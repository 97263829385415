<template>
  <div>
    <BaseHeaderW color="primary"></BaseHeaderW>
    <v-carousel
      cycle
      height="400"
      show-arrows-on-hover
      v-if="PropertyData.IDtype === 'R'"
    >
      <span v-for="(item, j) in PropertyData.Images" :key="j">
        <v-carousel-item
          :src="item.url"
          reverse-transition="fade-transition"
          transition="fade-transition"
          width="100%"
        ></v-carousel-item>
      </span>
    </v-carousel>
    <v-carousel
      cycle
      height="400"
      show-arrows-on-hover
      v-if="PropertyData.IDtype === 'Z'"
    >
      <span v-for="(item, j) in PropertyData.Images" :key="j">
        <v-carousel-item
          :src="'https://lid.zoocdn.com/u/1600/1200/' + item.filename"
          reverse-transition="fade-transition"
          transition="fade-transition"
          width="100%"
        ></v-carousel-item>
      </span>
    </v-carousel>
    <div style="padding: 1%">
      <v-row>
        <v-col cols="12"
          ><h2>{{ PropertyData.address }}</h2>
          <h3 style="color: light-grey">
            Price: £ {{ PropertyData.price }}
          </h3></v-col
        >
        <v-col cols="12">
          <v-btn
            class="mx-auto"
            color="accent"
            @click="navgiate(PropertyData.url)"
          >
            View Property
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          ><p>Property Type: {{ PropertyData.subtype }}</p></v-col
        >
        <v-col>
          <p>Bathrooms: {{ PropertyData.bathrooms }}</p>
        </v-col>
        <v-col>
          <p>Bedrooms: {{ PropertyData.bedrooms }}</p>
        </v-col>
      </v-row>
    </div>
    <div style="padding: 0% 2%" v-if="PropertyData.IDtype === 'R'">
      <!-- <p v-html="PropertyData.WebData.propertyData.text.description"></p> -->
      <h3>Key Features</h3>
      <ul
        v-for="(item, index) in PropertyData.WebData.propertyData.keyFeatures"
        :key="index"
      >
        <li>{{ item }}</li>
      </ul>
    </div>
    <div style="padding: 0% 2%" v-if="PropertyData.IDtype === 'Z'">
      <!-- <h3>Overview</h3>
      <p
        v-html="
          PropertyData.WebData.props.pageProps.listingDetails
            .detailedDescription
        "
      ></p> -->
      <h3>Key Features</h3>
      <ul
        v-for="(item, index) in PropertyData.WebData.props.pageProps
          .listingDetails.features.bullets"
        :key="index"
      >
        <li>{{ item }}</li>
      </ul>
    </div>
    <div class="analysis">
      <h3>Property Defect Report</h3>
      <p>Our analysis of this properties images has identified issues:**</p>
      <h4>Summery</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Title</th>
              <th class="text-left">Defect Identified</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, m) in descriptions.Sections" :key="m">
              <td>{{ d.Title }}</td>
              <td>
                <span v-if="issues.includes(d.Title)"
                  ><v-icon color="orange accent-4" light right>
                    mdi-emoticon-cry </v-icon
                  >Yes</span
                ><span v-if="!issues.includes(d.Title)"
                  ><v-icon color="green accent-4" light right>
                    mdi-emoticon-cool-outline </v-icon
                  >No</span
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />
      <span>
        <h4>Detailed Report of each issue.</h4>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel
            v-for="(d, m) in descriptions.Sections"
            :key="m"
            v-show="issues.includes(d.Title)"
          >
            <v-expansion-panel-header>
              <b>{{ d.Title }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <b>Overview</b>
                <p>{{ d.Overview }}</p>
                <b>Causes</b>
                <ul v-for="(c, k) in d.Causes" :key="k">
                  <li>{{ c }}</li>
                </ul>
                <br />
              </div>
              <div>
                <b>How do I check for {{ d.Title }}</b>
                <p>{{ d.howtocheck.Overview }}</p>
                <ul v-for="(c, l) in d.howtocheck.Causes" :key="l">
                  <li>{{ c }}</li>
                </ul>
              </div>
              <div>
                <br />
                <p><b>Estimated Cost for making changes to the property:</b></p>
                <ul v-for="(cb, index) in d.CostBreakdown" :key="index">
                  <li>{{ cb.Name }} : {{ cb.Cost }}</li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import descriptions from "../assets/descriptions.json";
const axios = require("axios");

export default {
  data: () => ({
    PropertyData: {},
    descriptions: descriptions,
    panel: [0],
    issues: [],
  }),
  async created() {
    const x = localStorage.getItem("property");
    this.PropertyData = JSON.parse(x);
    console.log(this.PropertyData);
    if (this.PropertyData.Images.length >= 9) {
      for (let index = 0; index < this.PropertyData.Images.length; index++) {
        if (this.PropertyData.IDtype === "R") {
          const element = this.PropertyData.Images[index];
          this.getAnalysis(element.url);
        } else if (this.PropertyData.IDtype === "Z") {
          const element =
            "https://lid.zoocdn.com/u/1600/1200/" +
            this.PropertyData.Images[index].filename;
          console.log(element);
          this.getAnalysis(element);
        }
      }
    } else {
      for (let index = 0; index < this.PropertyData.Images.length; index++) {
        const element = this.PropertyData.Images[index];
        setTimeout(this.getAnalysis(element.url), 1000);
        // setInterval(this.getAnalysis(element.url), 1000);
      }
    }
  },
  methods: {
    getAnalysis(imagedata) {
      const url =
        "https://fixuprealestate-prediction.cognitiveservices.azure.com/customvision/v3.0/Prediction/4c4a25cf-804a-495b-9794-2d591d8ef826/detect/iterations/Iteration4/url";
      const headers = {
        "Prediction-Key": process.env.VUE_APP_PREDICTIONKEY,
      };
      axios
        .post(
          url,
          {
            url: imagedata,
          },
          {
            headers: headers,
          }
        )
        .then(async (response) => {
          let tempStore = response.data.predictions;
          tempStore.forEach((x) => {
            if (x.probability > 0.7) {
              this.issues.push(x.tagName);
            }
          });
          this.issues = [...new Set(this.issues)];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    navgiate(val) {
      const myArray = val.split("/");
      const type = myArray[4];
      const id = myArray[5];

      if (type === "rightmove") {
        window.open("https://www.rightmove.co.uk/properties/" + id, "_blank");
      } else if (type === "zoopla") {
        window.open(
          "https://www.zoopla.co.uk/for-sale/details/" + id,
          "_blank"
        );
      } else if (type === "otm") {
        window.open("https://www.onthemarket.com/details/" + id, "_blank");
      }
    },
  },
  computed: {
    ...mapState(["search"]),
  },
};
</script>

<style>
.propCards {
  display: inline-block !important;
  width: 24%;
  border-radius: 0px !important;
  margin: 0.5%;
}
.loading {
  padding: 5%;
  margin-left: 40%;
  margin-top: 10%;
}
.analysis {
  padding: 2% 2%;
  width: 75%;
}
@media screen and (max-width: 850px) {
  .propCards {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .loading {
    margin-left: 20%;
    margin-top: 55%;
  }
  .analysis {
    padding: 2% 2%;
    width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .loading {
    margin-left: 35%;
    margin-top: 25%;
  }
}
</style>
